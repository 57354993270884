

export const isDev = false
export function vuetifyConfiguration() {
  const options = JSON.parse('{"customVariables":["~/assets/variables.scss"],"theme":{"dark":false,"themes":{"dark":{"primary":"#1976d2","accent":"#424242","secondary":"#ff8f00","info":"#26a69a","warning":"#ffc107","error":"#dd2c00","success":"#00e676"}}},"ssr":true}')
  
  
  
  
  return options
}

