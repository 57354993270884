import revive_payload_client_4sVQNw7RlN from "/notarias_webs_nuxt/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import vuetify_icons_RMzWu406ID from "/notarias_webs_nuxt/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-icons.mjs";
import unhead_KgADcZ0jPj from "/notarias_webs_nuxt/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/notarias_webs_nuxt/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/notarias_webs_nuxt/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/notarias_webs_nuxt/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/notarias_webs_nuxt/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/notarias_webs_nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/notarias_webs_nuxt/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_C2QvvtgUo3 from "/notarias_webs_nuxt/node_modules/@dargmuesli/nuxt-cookie-control/dist/runtime/plugin.js";
import plugin_client_LcKgStRyi6 from "/notarias_webs_nuxt/node_modules/nuxt-gtag/dist/runtime/plugin.client.mjs";
import vuetify_no_client_hints_wMKVLl9fyU from "/notarias_webs_nuxt/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-no-client-hints.mjs";
import chunk_reload_client_UciE0i6zes from "/notarias_webs_nuxt/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import analytics_client_mtqVw3YRXk from "/notarias_webs_nuxt/plugins/analytics.client.ts";
import error_handler_kEP5FliEXj from "/notarias_webs_nuxt/plugins/error-handler.ts";
import route_plugin_AN7Rw5PDaV from "/notarias_webs_nuxt/plugins/route-plugin.ts";
import social_chat_client_DTAnU67KfR from "/notarias_webs_nuxt/plugins/social-chat.client.ts";
import thtml_nILhCOWH8G from "/notarias_webs_nuxt/plugins/thtml.ts";
import vuetify_nuxt_plugin_client_JOtFoHdxcp from "/notarias_webs_nuxt/.nuxt/vuetify-nuxt-plugin.client.mjs";
export default [
  revive_payload_client_4sVQNw7RlN,
  vuetify_icons_RMzWu406ID,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_C2QvvtgUo3,
  plugin_client_LcKgStRyi6,
  vuetify_no_client_hints_wMKVLl9fyU,
  chunk_reload_client_UciE0i6zes,
  analytics_client_mtqVw3YRXk,
  error_handler_kEP5FliEXj,
  route_plugin_AN7Rw5PDaV,
  social_chat_client_DTAnU67KfR,
  thtml_nILhCOWH8G,
  vuetify_nuxt_plugin_client_JOtFoHdxcp
]