<script setup lang="ts">
import type { NuxtError } from '#app'

const props = defineProps({
  error: Object as () => NuxtError
})

const handleError = () => clearError({ redirect: '/' })
</script>

<template>
  <div>
    <h2 v-html="error?.statusCode"></h2>
    <p v-html="error?.message"></p>
    <p v-html="error?.stack"></p>
    <button @click="handleError">Limpiar errores</button>
  </div>
</template>