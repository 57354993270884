import { useStore } from '../store/index'
export default defineNuxtPlugin(nuxtApp => {
  const { gtag, initialize } = useGtag()
  const cookieControl = useCookieControl()
  const store = useStore()
  if (cookieControl.cookiesEnabledIds.value?.includes('gtag')) {
    if(store?.NOTARIA?.Personalizar?.GoogleAnalyticsID){
      console.log("initialice gtag")
      initialize(store.NOTARIA.Personalizar.GoogleAnalyticsID)
      
      gtag('consent', 'update', {
        ad_user_data: 'granted',
        ad_personalization: 'granted',
        ad_storage: 'granted',
        analytics_storage: 'granted'
      })
    }
  }

  function acceptTracking() {
    
  }
})