<script setup lang="ts">
import { useStore } from './store/index'
const store = useStore();
const route = useRoute()
const headers = useRequestHeaders()

const compAppClass = computed(()=>{
  if (store.ModoAccesible){
    return 'accesible-mode';
  }
  return '';
});
console.log('Create APP')
console.log('route: '+route.fullPath)
const id = await useAsyncData("initNotaria", ()=>store.initNotaria(headers).then(() => true));





function createHead(){
  let head={}
  if(store.css_list){
    head.link=[]
    store.css_list.forEach(css =>{
      head.link.push({ rel: 'stylesheet', href: css })
    })
  }
  return head
}
useHead(createHead())

if(store.NOTARIA.Personalizar.UnderConstruction && store.token == null){
  if(route.name!='UnderConstruction'){
    navigateTo("/underconstruction")
  }
}


// onMounted(()=>{
//   console.log("Mounted!");
//   console.log(store.NOTARIA);
// // })
// // onCreated(()=> {

//   console.log("Create");

//   //router.beforeEach(async (to, _, next) => await BeforeEach(to, _, next, router, store, idnotaria));

//   try {
//     store.initNotaria(headers);



//     // var RecaptchaGroups=[null,'6LcoRlklAAAAAE3SgHyos1jSBhRG-NvwKESOy3MT','6Ld6r1QpAAAAAKECU6jo-xhLRHsnu_2ofmOS5v4W','6Lep4lQpAAAAAP-mTLTamzilYsfiByuPs-Q1GsAj','6Lcg41QpAAAAAMQ2wDYA9ryclG3RIYLcXHG6lu63']
//     // var RecaptchaGroup =1
//     // if(store.state.NOTARIA.Personalizar.RecaptchaGroup){
//     //   RecaptchaGroup = store.state.NOTARIA.Personalizar.RecaptchaGroup
//     // }
//     // Vue.use(VueReCaptcha, { siteKey: RecaptchaGroups[RecaptchaGroup] })
//     // if(process.env.NODE_ENV === 'production'){
//     //   Vue.use(VueGtag, {
//     //     config: { id: store.state.NOTARIA.Personalizar.GoogleAnalyticsID },
//     //     appName: store.state.NOTARIA.Nombre,
//     //     pageTrackerScreenviewEnabled: true
//     //   }, router);
//     // }



//     // if (process.env.NODE_ENV === 'development') {
//     //   const VueAxe = require('vue-axe').default
//     //   Vue.use(VueAxe)
//     // }
//     store.FecthModoAccesible();
//   } catch (ex) {
//     console.error(ex);
//   }

// })
//create();
// function h() {
//   var h = {
//     link: []
//   }
//   if (store.css_list) {
//     store.css_list.forEach(c => {
//       h.link.push({
//         rel: "stylesheet",
//         href: c
//       }
//       )
//     })
//   }
//   return h;
// }
</script>
<template>
  <v-app id="app" :class="compAppClass">
    <NuxtLayout v-if="store.NOTARIA"/>
  </v-app>
</template>